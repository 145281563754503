.App {
  font-family: sans-serif;
  text-align: center;
  margin-top: 56px;
}

a {

    text-decoration: none;
}

::-ms-reveal {
  display: none !important;
}
  