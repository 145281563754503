.page-title {
    display: inline-flex;
    margin-bottom: 18px;
    width: 66%;
    font-size: 44px;
    line-height: 56px;
    color: #515151
}

.page-title-with-subtitle {
    display: block;
    text-align: left;
    margin-bottom: 18px;
    width: 66%;
}

.page-brand {
    display: block;
    text-align: left;
    margin-bottom: 18px;
    width: 66%;
}

.page-content {
    width: 66%;
}



@media screen and (max-width: 960px) {
    .page-footer-width {
        width: 42%;
    }
}

@media screen and (max-width: 595px) {
    .page-footer-width {
        width: 66%;
    }
}

@media screen and (min-width: 960px) {
    .page-footer-width {
        width: 20.3%;
    }
}

.page-footer {
    position: fixed;
    bottom: 56px;
    text-align: left;
}

@media screen and (max-height: 790px) {
    .page-footer-visibility {
        visibility: hidden;
    }
}

@media screen and (min-height: 790px) {
    .page-footer-visibility {
        visibility: visible;
    }
}

.line-height {
    margin-bottom: 18px;
}

.header-text {
    font-size: 44px;
    line-height: 56px;
    color: #515151
}